import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useMaterialUIController } from "context";
import MDTypography from "components/MDTypography";
import { CardActions, CardMedia, Tooltip } from "@mui/material";
import MDButton from "components/MDButton";

const ViewCard = (props) => {
  const [controller] = useMaterialUIController();

  const { sidenavColor } = controller;
  const { item } = props;

  return (
    <Card
      sx={{
        maxWidth: 350,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
      }}
    >
      <CardMedia
        sx={{ height: 140, width: "100%" }}
        image={item.cardImageURL || ""}
        title={item.title}
      />

      <CardContent>
        <MDTypography gutterBottom variant="h5" component="div">
          {item.title}
        </MDTypography>
        <MDTypography variant="body2" color="text.secondary">
          {item.subTitle}
        </MDTypography>
      </CardContent>

      <CardActions>
        <Tooltip title={item?.buttonURL} placement="top">
          <MDButton
            variant="contained"
            color={sidenavColor}
            size="small"
            onClick={() => {
              if (item?.buttonURL) {
                window.open(item?.buttonURL, "_blank");
              }
            }}
          >
            {item.buttonText}
          </MDButton>
        </Tooltip>
      </CardActions>
    </Card>
  );
};

export default ViewCard;
