import { Box, Card, CardContent, Chip, IconButton } from "@mui/material";
import MDBox from "components/MDBox";
import React, { useState } from "react";
import ViewFormCard from "../ContentViewCards/ViewFormCard";
import ViewListContent from "../ContentViewCards/ViewListCard";
import ViewCard from "../ContentViewCards/ViewCard";
import EditorHTMLViewBox from "components/UIComponents/RichTextEditor/EditorHTMLViewBox";
import { Handle, Position } from "@xyflow/react";
import EditIcon from "@mui/icons-material/Edit";
import MDButton from "components/MDButton";
import { useMaterialUIController } from "context";
import MessageUpdateDialogBox from "../MessageUpdateDialog";
import QRCodeImage from "../../../../assets/images/QRCode.png";
import ViewPaymentCard from "../ContentViewCards/ViewPaymentCard";
import MDTypography from "components/MDTypography";

const Content = ({ data, isConnectable }) => {
  const [controller] = useMaterialUIController();
  const { sidenavColor } = controller;
  const [hovered, setHovered] = useState(false);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [messageData, setMessageData] = useState({});

  const handleMouseEnter = () => setHovered(true);
  const handleMouseLeave = () => setHovered(false);

  const ContentRenderer = ({ type, item }) => {
    const renderContent = {
      textResponse: (
        <Card>
          <CardContent>
            <MDBox
              display="flex"
              flexWrap="wrap"
              gap={1}
              style={{ width: "400px" }}
            >
              <div key={item._id}>
                {item.isIndividual && (
                  <MDTypography mb={2}>{item.purpose} - </MDTypography>
                )}
                <EditorHTMLViewBox data={item.textResponse} />
              </div>
            </MDBox>
          </CardContent>
        </Card>
      ),
      textResponseSms: (
        <Card>
          <CardContent>
            <MDBox
              display="flex"
              flexWrap="wrap"
              gap={1}
              style={{ width: "400px" }}
            >
              <div key={item._id}>
                {(item.purpose === "Expiration Reminder" ||
                  item.purpose === "Validation Reminder SMS" ||
                  item.purpose === "Help SMS") && (
                  <MDTypography mb={2}>{item.purpose} - </MDTypography>
                )}
                <MDTypography>{item.textMessage}</MDTypography>
              </div>
            </MDBox>
          </CardContent>
        </Card>
      ),
      button: (
        <MDBox display="flex" flexWrap="wrap" gap={1}>
          <div key={item._id}>
            <MDButton color={sidenavColor}>{item.buttonText}</MDButton>
          </div>
        </MDBox>
      ),
      suggestions: (
        <MDBox display="flex" flexWrap="wrap" gap={1}>
          <div key={item._id}>
            <Chip
              label={item.buttonText}
              sx={{ color: "#fff", background: "black" }}
            />
          </div>
        </MDBox>
      ),
      form: (
        <MDBox display="flex" flexWrap="wrap" gap={1}>
          <div key={item._id}>
            <ViewFormCard item={item} />
          </div>
        </MDBox>
      ),
      list: (
        <MDBox display="flex" flexWrap="wrap" gap={1}>
          <div key={item._id}>
            <ViewListContent item={item} />
          </div>
        </MDBox>
      ),
      card: (
        <MDBox display="flex" flexWrap="wrap" gap={1}>
          <div key={item._id}>
            <ViewCard item={item} />
          </div>
        </MDBox>
      ),
      carousel: (
        <MDBox display="flex" flexWrap="wrap" gap={1}>
          <div key={item._id}>{/* <CarousalViewCard item={item} /> */}</div>
        </MDBox>
      ),
      QRCode: (
        <>
          <Box component="img" src={QRCodeImage} width="120px" height="120px" />
        </>
      ),
      paymentPage: (
        <>
          <MDBox p={2}>
            <ViewPaymentCard brandLogo={item?.brandLogo} />
          </MDBox>
        </>
      ),
    };

    return renderContent[type] || null;
  };

  return (
    <>
      <Handle
        type="target"
        position={Position.Top}
        style={{ background: "#555" }}
        isConnectable={isConnectable}
      />
      <MDBox
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        position="relative"
        sx={{
          opacity:
            hovered &&
            data?.content?.type !== "suggestions" &&
            data?.content?.type !== "paymentPage" &&
            data?.content?.type !== "QRCode"
              ? 0.5
              : 1,
          transition: "opacity 0.3s ease",
        }}
      >
        <ContentRenderer
          type={data?.content?.type}
          item={data.content}
          brandLogo={data.brandLogo || ""}
        />
        {hovered &&
          data?.content?.type !== "suggestions" &&
          data?.content?.type !== "paymentPage" &&
          data?.content?.type !== "QRCode" && (
            <IconButton
              sx={{
                zIndex: "999",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                backgroundColor: "rgba(255, 255, 255, 0.8)",
                ":hover": { backgroundColor: "rgba(255, 255, 255, 1)" },
              }}
              onClick={() => {
                console.log("data.content ===>", data.content);
                setMessageData(data.content);
                setDialogOpen(true);
              }}
            >
              <EditIcon fontSize="medium" />
            </IconButton>
          )}
      </MDBox>
      <Handle
        type="source"
        position={Position.Bottom}
        style={{ background: "#555" }}
        isConnectable={isConnectable}
      />
      <MessageUpdateDialogBox
        dialogOpen={dialogOpen}
        onClose={() => setDialogOpen(false)}
        messageData={messageData}
      />
    </>
  );
};

export default Content;
