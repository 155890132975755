import React, { useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import MDBox from "components/MDBox";
import { Card } from "@mui/material";

import { useDispatch } from "react-redux";

const ViewListContent = (props) => {
  const { item } = props;

  return (
    <MDBox >
      <Card
        sx={{
          maxWidth: 450,
          padding: "20px",
          borderRadius: "16px",
          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "#fff",
          position: "relative",
        }}
      >
        <List
          sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
        >
          {(item.list || []).map((list, index) => (
            <ListItem key={index}>
              <ListItemAvatar>
                <Avatar>
                  <img
                    src={list.listImage || ""}
                    alt={list.title}
                    style={{ width: "100%", height: "100%" }}
                  />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={list.title} secondary={list.subTitle} />
            </ListItem>
          ))}
        </List>
      </Card>
    </MDBox>
  );
};

export default ViewListContent;
