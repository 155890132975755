import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PlacePicker from "components/MDPlacePicker/PlacePicker";
import ChatBotFlow from "./components/ChatbotFlow";
import { debounce } from "lodash";
import { useCallback, useEffect, useMemo } from "react";
import CircularIndeterminate from "components/MDLoading";
import { getChatbotFlow } from "store/slice/chatbotFlow/chatbotFlowSlice";

function Messages() {
  const dispatch = useDispatch();
  const [controller] = useMaterialUIController();
  const { sidenavColor } = controller;
  const location = useLocation();
  const placesData = useSelector((state) => state.places?.selectedPlace);
  const flowData = useSelector((state) => state.chatbotFlow?.chatbotFlow || {});
  const loading = useSelector((state) => state.chatbotFlow?.loading || false);

  let placeId =
    placesData?._id || new URLSearchParams(location?.search).get("placeId");

  const getChatbotFlowData = useCallback(async () => {
    dispatch(getChatbotFlow(placeId));
  }, [dispatch, placeId]);

  const debounceFn = useMemo(
    () => debounce(getChatbotFlowData, 1000),
    [getChatbotFlowData]
  );

  useEffect(() => {
    debounceFn();
  }, [debounceFn]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={1}
                mt={-2}
                py={1}
                px={1}
                variant="gradient"
                bgColor={sidenavColor}
                borderRadius="lg"
                coloredShadow={sidenavColor}
                className="d-flex align-items-center gap-2"
              >
                <MDTypography
                  variant="h6"
                  color="white"
                  className="flex-grow-1"
                >
                  Design Bot
                </MDTypography>
              </MDBox>
              <MDBox p={3}>
                <PlacePicker />
              </MDBox>
              <MDBox>
                <ChatBotFlow flowData={flowData} />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <CircularIndeterminate
        type="full"
        size={20}
        text="Please wait... "
        open={loading}
      />
    </DashboardLayout>
  );
}

export default Messages;
