import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetChatbotFLowTestAPI } from "api/chatbotFlow";
import { GetChatbotFLowAPI } from "api/chatbotFlow";
import { getPlivosAPI } from "api/plivoAPI";

const initialState = {
  chatbotFlow: {},
  loading: false,
};

export const getChatbotFlow = createAsyncThunk(
  "chatbotFlow/getChatbotFlow",
  async (data) => {
    try {
      const response = await GetChatbotFLowAPI(data);
      return response.data;
    } catch (err) {
      console.log("get chatbot flow error ======>", err);
    }
  }
);

export const getChatbotFlowTest = createAsyncThunk(
  "chatbotFlow/getChatbotFlowTest",
  async (data) => {
    try {
      const response = await GetChatbotFLowTestAPI(data);
      return response.data;
    } catch (err) {
      console.log("get chatbot flow error ======>", err);
    }
  }
);

export const chatbotFlowSlice = createSlice({
  name: "chatbotFlow",
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(getChatbotFlow.pending, (state) => {
        state.loading = true;
      })
      .addCase(getChatbotFlow.fulfilled, (state, { payload }) => {
        state.chatbotFlow = payload.data;
        state.loading = false;
      })
      .addCase(getChatbotFlow.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getChatbotFlowTest.pending, (state) => {
        state.loading = true;
      })
      .addCase(getChatbotFlowTest.fulfilled, (state, { payload }) => {
        state.chatbotFlow = payload.data;
        state.loading = false;
      })
      .addCase(getChatbotFlowTest.rejected, (state) => {
        state.loading = false;
      }),
});

export default chatbotFlowSlice.reducer;
