import React, { useState } from "react";
import MDBox from "components/MDBox";
import {
  Card,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  TextField,
} from "@mui/material";

import { useDispatch } from "react-redux";

import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDDropDown from "components/MDDropDown";
import { DatePicker, DateRangePicker } from "rsuite";
import PhoneInput from "react-phone-number-input";
import { useMaterialUIController } from "context";
import MDButton from "components/MDButton";

const ViewFormCard = (props) => {
  const [controller] = useMaterialUIController();

  const { sidenavColor } = controller;
  const { item } = props;

  const [selectedRadio, setSelectedRadio] = useState("");
  const [mobileValue, setMobileValue] = useState("");

  const handleRadioChange = (value) => {
    setSelectedRadio(value);
  };

  return (
    <MDBox>
      <Card
        sx={{
          maxWidth: 450,
          padding: "20px",
          borderRadius: "16px",
          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "#fff",
          position: "relative",
        }}
      >
        <MDBox
          sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
        >
          <Grid container spacing={2} className="mt-1">
            <Grid item xs={12} className="text-center">
              <MDTypography variant="body" fontWeight="regular">
                {item.title}
              </MDTypography>
            </Grid>
            <Grid item xs={12} className="text-center">
              <MDTypography
                variant="subtitle2"
                fontWeight="regular"
                color="text"
              >
                {item.subTitle}
              </MDTypography>
            </Grid>

            <Grid item xs={12}>
              <MDBox>
                {item.form.map((field, index) => (
                  <Grid container spacing={2} key={index}>
                    {field.fieldType === "input" && (
                      <Grid item xs={12} my={1}>
                        <MDInput
                          type={field.type}
                          name={field.name}
                          label={field.label}
                          placeholder={field.placeHolder}
                        />
                      </Grid>
                    )}
                    {field.fieldType === "textField" && (
                      <Grid item xs={12} my={1}>
                        <FormControl fullWidth>
                          <TextField
                            variant="standard"
                            multiline
                            name={field.name}
                            label={field.label}
                            placeholder={field.placeHolder}
                          />
                        </FormControl>
                      </Grid>
                    )}
                    {field.fieldType === "dropdown" && (
                      <Grid item xs={12} my={1}>
                        <FormControl fullWidth>
                          <InputLabel>{field.label}</InputLabel>
                          <MDDropDown name={field.name} label={field.label}>
                            {field.selects.map((option, idx) => (
                              <MenuItem value={option.value} key={idx}>
                                {option.key}
                              </MenuItem>
                            ))}
                          </MDDropDown>
                        </FormControl>
                      </Grid>
                    )}
                    {field.fieldType === "checkbox" && (
                      <Grid item xs={12} my={1}>
                        <MDBox display="flex" flexDirection="column">
                          <InputLabel>{field.groupLabel}</InputLabel>
                          <MDBox display="flex" flexWrap="wrap">
                            {field.checkboxes.map((checkbox, idx) => (
                              <MDBox
                                key={idx}
                                display="flex"
                                alignItems="center"
                                mr={2}
                              >
                                <Checkbox
                                  name={checkbox.value}
                                  value={checkbox.value}
                                  size="small"
                                />
                                <span style={{ fontSize: "15px" }}>
                                  {checkbox.label}
                                </span>
                              </MDBox>
                            ))}
                          </MDBox>
                        </MDBox>
                      </Grid>
                    )}
                    {field.fieldType === "radio" && (
                      <Grid item xs={12} my={1}>
                        <MDBox display="flex" flexDirection="column">
                          <InputLabel>{field.groupLabel}</InputLabel>
                          <MDBox display="flex" flexWrap="wrap">
                            {field.radios.map((radio, idx) => (
                              <MDBox
                                key={idx}
                                display="flex"
                                alignItems="center"
                                mr={2}
                              >
                                <Radio
                                  name={radio.value}
                                  value={radio.value}
                                  checked={selectedRadio === radio.value}
                                  onChange={() =>
                                    handleRadioChange(radio.value)
                                  }
                                />
                                <span style={{ fontSize: "15px" }}>
                                  {radio.label}
                                </span>
                              </MDBox>
                            ))}
                          </MDBox>
                        </MDBox>
                      </Grid>
                    )}
                    {field.fieldType === "picker" && (
                      <Grid item xs={12} my={1}>
                        <FormControl fullWidth>
                          {field.pickerType === "dateRangePicker" ||
                          field.pickerType === "dateTimeRangePicker" ? (
                            <DateRangePicker
                              id={field.name}
                              placeholder={field.placeHolder}
                              character=" - "
                              showOneCalendar={false}
                              size="sm"
                              format={field.format}
                              ranges={[]}
                              clearButton={true}
                            />
                          ) : (
                            <DatePicker
                              id={field.name}
                              placeholder={field.placeHolder}
                              character=" - "
                              showOneCalendar={false}
                              size="sm"
                              format={field.format}
                              clearButton={true}
                            />
                          )}
                        </FormControl>
                      </Grid>
                    )}
                    {field.fieldType === "mobile" && (
                      <Grid item xs={12} my={1}>
                        <PhoneInput
                          defaultCountry="US"
                          placeholder="Enter phone number"
                          value={mobileValue}
                          onChange={setMobileValue}
                        />
                      </Grid>
                    )}
                  </Grid>
                ))}
              </MDBox>
            </Grid>
            <Grid item xs={12}  className="text-right">
              <MDButton variant="contained" color={sidenavColor} size="small">
                Submit
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
      </Card>
    </MDBox>
  );
};

export default ViewFormCard;
