import React, { useEffect } from "react";
import {
  ReactFlow,
  useNodesState,
  useEdgesState,
  Controls,
  addEdge,
} from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import Content from "./Nodes/Content";
import MDBox from "components/MDBox";

const nodeTypes = {
  content: Content,
};

const snapGrid = [20, 20];
const connectionLineStyle = { stroke: "black", strokeWidth: 2 };

const ChatBotFlow = ({ flowData }) => {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  useEffect(() => {
    const dynamicNodes = [];
    const dynamicEdges = [];
    let positionY = 0;
    console.log("flowData ===>", flowData);
    // Add Welcome Message Node
    if (flowData.welcomeMessage) {
      dynamicNodes.push(
        {
          id: "qrCode",
          type: "content",
          data: { content: { type: "QRCode" } },
          position: { x: 400, y: positionY - 200 },
        },

        {
          id: "welcome",
          type: "content",
          data: {
            content: {
              type: "textResponse",
              textResponse: flowData.welcomeMessage.message,
              purpose: flowData.welcomeMessage.purpose,
              _id: flowData.welcomeMessage._id || false,
            },
          },
          position: { x: 250, y: positionY },
        }
      );
      dynamicEdges.push({
        id: "qrCode-welcome",
        source: "qrCode",
        target: "welcome",
        type: "step",
      });
      positionY += 200;
    }

    if (flowData.individualResponses) {
      dynamicNodes.push(
        {
          id: "unavailability",
          type: "content",
          data: {
            content: {
              isIndividual: true,
              type: "textResponse",
              textResponse:
                flowData?.individualResponses?.unavailabilityResponse?.message,
              purpose:
                flowData?.individualResponses?.unavailabilityResponse?.purpose,
              _id:
                flowData?.individualResponses?.unavailabilityResponse?._id ||
                false,
            },
          },
          position: { x: -600, y: -200 },
        },
        {
          id: "extensionReminderSms",
          type: "content",
          data: {
            content: {
              type: "textResponseSms",
              textMessage:
                flowData?.individualResponses?.extensionReminderMessage
                  ?.textMessage,
              purpose:
                flowData?.individualResponses?.extensionReminderMessage
                  ?.purpose,
              _id:
                flowData?.individualResponses?.extensionReminderMessage?._id ||
                false,
            },
          },
          position: { x: -600, y: positionY - 200 },
        },
        {
          id: "extensionLinkExpire",
          type: "content",
          data: {
            content: {
              isIndividual: true,
              type: "textResponse",
              textResponse:
                flowData?.individualResponses?.extensionLinkExpireResponse
                  ?.message,
              purpose:
                flowData?.individualResponses?.extensionLinkExpireResponse
                  ?.purpose,
              _id:
                flowData?.individualResponses?.extensionLinkExpireResponse
                  ?._id || false,
            },
          },
          position: { x: -600, y: 300 },
        },
        {
          id: "validationLinkExpire",
          type: "content",
          data: {
            content: {
              isIndividual: true,
              type: "textResponse",
              textResponse:
                flowData?.individualResponses?.validationLinkExpireResponse
                  ?.message,
              purpose:
                flowData?.individualResponses?.validationLinkExpireResponse
                  ?.purpose,
              _id:
                flowData?.individualResponses?.validationLinkExpireResponse
                  ?._id || false,
            },
          },
          position: { x: -600, y: 700 },
        },
        {
          id: "validationReminderSms",
          type: "content",
          data: {
            content: {
              type: "textResponseSms",
              textMessage:
                flowData?.individualResponses?.validationReminderMessage
                  ?.textMessage,
              purpose:
                flowData?.individualResponses?.validationReminderMessage
                  ?.purpose,
              _id:
                flowData?.individualResponses?.validationReminderMessage?._id ||
                false,
            },
          },
          position: { x: -600, y: 900 },
        },
        {
          id: "helpSms",
          type: "content",
          data: {
            content: {
              type: "textResponseSms",
              textMessage:
                flowData?.individualResponses?.helpMessage?.textMessage,
              purpose: flowData?.individualResponses?.helpMessage?.purpose,
              _id: flowData?.individualResponses?.helpMessage?._id || false,
            },
          },
          position: { x: -600, y: 1200 },
        },
        {
          id: "unavailabilityExtension",
          type: "content",
          data: {
            content: {
              isIndividual: true,
              type: "textResponse",
              textResponse:
                flowData?.individualResponses?.unavailabilityExtensionResponse
                  ?.message,
              purpose:
                flowData?.individualResponses?.unavailabilityExtensionResponse
                  ?.purpose,
              _id:
                flowData?.individualResponses?.unavailabilityExtensionResponse
                  ?._id || false,
            },
          },
          position: { x: -600, y: 1500 },
        },
        {
          id: "help",
          type: "content",
          data: {
            content: {
              isIndividual: true,
              type: "textResponse",
              textResponse:
                flowData?.individualResponses?.helpResponse?.message,
              purpose: flowData?.individualResponses?.helpResponse?.purpose,
              _id: flowData?.individualResponses?.helpResponse?._id || false,
            },
          },
          position: { x: -600, y: 1800 },
        },
        {
          id: "learnMore",
          type: "content",
          data: {
            content: {
              isIndividual: true,
              type: "textResponse",
              textResponse:
                flowData?.individualResponses?.learnMoreResponse?.message,
              purpose:
                flowData?.individualResponses?.learnMoreResponse?.purpose,
              _id:
                flowData?.individualResponses?.learnMoreResponse?._id || false,
            },
          },
          position: { x: -600, y: 2100 },
        },
        {
          id: "contact",
          type: "content",
          data: {
            content: {
              isIndividual: true,
              type: "textResponse",
              textResponse:
                flowData?.individualResponses?.contactResponse?.message,
              purpose: flowData?.individualResponses?.contactResponse?.purpose,
              _id: flowData?.individualResponses?.contactResponse?._id || false,
            },
          },
          position: { x: -600, y: 2500 },
        }
      );
    }

    if (flowData?.flows?.SMSFlow) {
      dynamicNodes.push(
        {
          id: "welcomeSms",
          type: "content",
          data: {
            content: {
              type: "textResponseSms",
              textMessage: flowData?.SMSFlow?.welcomeMessage?.textMessage,
              purpose: flowData?.SMSFlow?.welcomeMessage?.purpose,
              _id: flowData?.SMSFlow?.welcomeMessage?._id || false,
            },
          },
          position: { x: 800, y: positionY - 400 },
        },
        {
          id: "licensePlateSubmissionSms",
          type: "content",
          data: {
            content: {
              type: "textResponseSms",
              textMessage:
                flowData?.SMSFlow?.licensePlateSubmissionMessage?.textMessage,
              purpose:
                flowData?.SMSFlow?.licensePlateSubmissionMessage?.purpose,
              _id:
                flowData?.SMSFlow?.licensePlateSubmissionMessage?._id || false,
            },
          },
          position: { x: 1500, y: positionY - 400 },
        },
        {
          id: "paymentConfirmationSms",
          type: "content",
          data: {
            content: {
              type: "textResponseSms",
              textMessage:
                flowData?.SMSFlow?.paymentConfirmationMessage?.textMessage,
              purpose: flowData?.SMSFlow?.paymentConfirmationMessage?.purpose,
              _id: flowData?.SMSFlow?.paymentConfirmationMessage?._id || false,
            },
          },
          position: { x: 2100, y: positionY - 400 },
        }
      );
      dynamicEdges.push(
        {
          id: "qrCode-welcomeSms",
          source: "qrCode",
          target: "welcomeSms",
          type: "step",
          label: "Welcome SMS",
        },
        {
          id: "welcomeSms-licensePlateSubmissionSms",
          source: "welcomeSms",
          target: "licensePlateSubmissionSms",
          type: "step",
          label: "After License plate submission SMS",
        },
        {
          id: "licensePlateSubmissionSms-paymentConfirmationSms",
          source: "licensePlateSubmissionSms",
          target: "paymentConfirmationSms",
          type: "step",
          label: "Payment confirmation SMS",
        }
      );
    }

    // Add Suggestions Nodes
    const suggestionIds = [];
    if (flowData.suggestions?.length) {
      const horizontalSpacing = 2100 / flowData.suggestions?.length;
      flowData.suggestions.forEach((suggestion, index) => {
        const nodeId = `suggestion-${index}`;
        dynamicNodes.push({
          id: nodeId,
          type: "content",
          data: {
            content: {
              type: "suggestions",
              buttonText: suggestion.displayName,
            },
          },
          position: {
            x:
              horizontalSpacing / flowData.suggestions?.length +
              index * horizontalSpacing,
            y: positionY,
          },
        });

        dynamicEdges.push({
          id: `welcome-${nodeId}`,
          source: "welcome",
          target: nodeId,
          type: "step",
        });
        suggestionIds.push({ nodeId, suggestion });
      });
      positionY += 200;
    }

    const addFlowNodes = (
      flowKey,
      flowData,
      startY,
      parentSuggestionIds,
      brandLogo
    ) => {
      if (!flowData) return startY;

      const flowStartId = `${flowKey}Start`;
      const flowEndId = `${flowKey}End`;

      // Rate Selection Message
      if (flowData.rateSelectionMessage) {
        dynamicNodes.push({
          id: flowStartId,
          type: "content",
          data: {
            content: {
              type: "textResponse",
              textResponse: flowData.rateSelectionMessage.message,
              purpose: flowData.rateSelectionMessage.purpose,
              _id: flowData.rateSelectionMessage._id || false,
            },
          },
          position: {
            x:
              flowKey === "normalRateFlow"
                ? 0
                : flowKey === "freeRateFlow"
                ? 500
                : flowKey === "twoStepValidationFlow"
                ? 1000
                : flowKey === "payNowValidationLaterFlow"
                ? 1500
                : 2000,
            y: 300,
          },
        });
        parentSuggestionIds.forEach(({ nodeId }) => {
          dynamicEdges.push({
            id: `${nodeId}-${flowStartId}`,
            source: nodeId,
            target: flowStartId,
            type: "step",
          });
        });
        startY += 150;
      }

      // Add Flow Suggestions
      if (flowData.suggestions?.length) {
        const suggestionIds = [];
        const formId = `${flowKey}Form`;
        flowData.suggestions.forEach((suggestion, index) => {
          const nodeId = `${flowKey}-suggestion-${index}`;
          const horizontalSpacing = 100 + 200;
          dynamicNodes.push({
            id: nodeId,
            type: "content",
            data: {
              content: {
                type: "suggestions",
                buttonText: suggestion.displayName,
              },
            },
            position: { x: 2000 + index * horizontalSpacing, y: 500 },
          });

          dynamicEdges.push(
            {
              id: `${flowStartId}-${nodeId}`,
              source: flowStartId,
              target: nodeId,
              type: "step",
            },
            {
              id: `${nodeId}-${formId}`,
              source: nodeId,
              target: formId,
              type: "step",
            }
          );
          suggestionIds.push(nodeId);
        });
        startY += 200;
      }

      // Form Node
      if (flowData.formData) {
        const formId = `${flowKey}Form`;
        dynamicNodes.push({
          id: formId,
          type: "content",
          data: {
            content: {
              type: "form",
              _id: flowData.formData._id || false,
              title: flowData.formData.title,
              subTitle: flowData.formData.subTitle,
              purpose: flowData.formData.purpose,
              form: [
                ...(flowData.formData.licensePlate
                  ? [
                      {
                        fieldType: "input",
                        label: "License Plate",
                        type: "text",
                      },
                    ]
                  : []),
                ...(flowData.formData.cellPhoneNumber
                  ? [
                      {
                        fieldType: "input",
                        label: "Cell Phone Number",
                        type: "text",
                      },
                    ]
                  : []),
                ...(flowData.formData.validationCode
                  ? [
                      {
                        fieldType: "input",
                        label: "Validation Code",
                        type: "text",
                      },
                    ]
                  : []),
              ],
            },
          },
          position: {
            x:
              flowKey === "normalRateFlow"
                ? 0
                : flowKey === "freeRateFlow"
                ? 500
                : flowKey === "twoStepValidationFlow"
                ? 1000
                : flowKey === "payNowValidationLaterFlow"
                ? 1500
                : 2000,
            y: 600,
          },
        });
        dynamicEdges.push(
          ...(flowKey != "parentRateFlow"
            ? [
                {
                  id: `${flowStartId}-${formId}`,
                  source: flowStartId,
                  target: formId,
                  type: "step",
                },
              ]
            : [])
        );
        startY += 100;
      }

      // Payment Card Node
      if (flowData.paymentCardData) {
        const paymentCardId = `${flowKey}PaymentCard`;
        const paymentPageId = `${flowKey}PaymentPage`;
        dynamicNodes.push(
          {
            id: paymentCardId,
            type: "content",
            data: {
              content: {
                type: "card",
                _id: flowData.paymentCardData._id || false,
                purpose: flowData.paymentCardData.purpose,
                cardImageURL: flowData.paymentCardData.imageURL,
                title: flowData.paymentCardData.title,
                subTitle: flowData.paymentCardData.subTitle,
                buttonText: flowData.paymentCardData.buttonText,
              },
            },
            position: {
              x:
                flowKey === "normalRateFlow"
                  ? 0
                  : flowKey === "freeRateFlow"
                  ? 500
                  : flowKey === "twoStepValidationFlow"
                  ? 1000
                  : flowKey === "payNowValidationLaterFlow"
                  ? 1500
                  : 2000,
              y: flowKey === "payNowValidationLaterFlow" ? 1050 : 1000,
            },
          },
          {
            id: paymentPageId,
            type: "content",
            data: {
              content: { type: "paymentPage", brandLogo: brandLogo },
            },
            position: {
              x:
                flowKey === "normalRateFlow"
                  ? 0
                  : flowKey === "freeRateFlow"
                  ? 500
                  : flowKey === "twoStepValidationFlow"
                  ? 1000
                  : flowKey === "payNowValidationLaterFlow"
                  ? 1500
                  : 2000,
              y: 1400,
            },
          }
        );
        dynamicEdges.push(
          {
            id: `${paymentCardId}-${paymentPageId}`,
            source: paymentCardId,
            target: paymentPageId,
            type: "step",
          },

          {
            id: `${flowKey}Form-${paymentCardId}`,
            source: `${flowKey}Form`,
            target: paymentCardId,
            type: "step",
          }
        );
        startY += 150;
      }

      // Payment Success Message

      if (flowData.paymentSuccessMessage) {
        dynamicNodes.push({
          id: flowEndId,
          type: "content",
          data: {
            content: {
              type: "textResponse",
              textResponse: flowData.paymentSuccessMessage.message,
              purpose: flowData.paymentSuccessMessage.purpose,
              _id: flowData.paymentSuccessMessage._id || false,
            },
          },
          position: {
            x:
              flowKey === "normalRateFlow"
                ? 0
                : flowKey === "freeRateFlow"
                ? 500
                : flowKey === "twoStepValidationFlow"
                ? 1000
                : flowKey === "payNowValidationLaterFlow"
                ? 1500
                : 2000,
            y:
              flowKey === "freeRateFlow"
                ? 1000
                : flowKey === "twoStepValidationFlow"
                ? 1050
                : 2050,
          },
        });
        const paymentCardOrFormId = flowData.paymentCardData
          ? `${flowKey}PaymentCard`
          : `${flowKey}Form`;
        const paymentPageId = `${flowKey}PaymentPage`;

        if (flowKey !== "freeRateFlow" && flowKey !== "twoStepValidationFlow") {
          dynamicEdges.push({
            id: `${paymentPageId}-${paymentCardOrFormId}`,
            source: paymentPageId,
            target: flowEndId,
            type: "step",
          });
        } else {
          dynamicEdges.push({
            id: `${paymentCardOrFormId}-${flowEndId}`,
            source: paymentCardOrFormId,
            target: flowEndId,
            type: "step",
          });
        }

        startY += 150;
      }

      return startY;
    };

    if (flowData.flows?.normalRateFlow) {
      positionY = addFlowNodes(
        "normalRateFlow",
        flowData.normalRateFlow,
        positionY,
        suggestionIds.filter(
          ({ suggestion }) =>
            !suggestion.isRateOption &&
            !suggestion.isFreeRate &&
            !suggestion.secondStepValidation &&
            !suggestion.payNowValidationLaterFlow
        ),
        flowData.brandLogo
      );
    }

    if (flowData.flows?.freeRateFlow) {
      positionY = addFlowNodes(
        "freeRateFlow",
        flowData.freeRateFlow,
        positionY,
        suggestionIds.filter(({ suggestion }) => suggestion.isFreeRate),
        flowData.brandLogo
      );
    }
    if (flowData.flows?.twoStepValidationFlow) {
      positionY = addFlowNodes(
        "twoStepValidationFlow",
        flowData.twoStepValidationFlow,
        positionY,
        suggestionIds.filter(
          ({ suggestion }) => suggestion.secondStepValidation
        ),
        flowData.brandLogo
      );
    }
    if (flowData.flows?.payNowValidationLaterFlow) {
      positionY = addFlowNodes(
        "payNowValidationLaterFlow",
        flowData.payNowValidationLaterFlow,
        positionY,
        suggestionIds.filter(
          ({ suggestion }) => suggestion.payNowValidationLaterFlow
        ),
        flowData.brandLogo
      );
    }
    if (flowData.flows?.parentRateFlow) {
      positionY = addFlowNodes(
        "parentRateFlow",
        flowData.parentRateFlow,
        positionY,
        suggestionIds.filter(({ suggestion }) => suggestion.isRateOption),
        flowData.brandLogo
      );
    }

    setNodes(dynamicNodes);
    setEdges(dynamicEdges);
  }, [flowData]);

  const onConnect = (params) => {
    setEdges((eds) =>
      addEdge({ ...params, animated: false, style: connectionLineStyle }, eds)
    );
  };

  console.log("flowData ===>", flowData);

  return (
    <MDBox style={{ width: "100%", height: "100vh" }}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        nodeTypes={nodeTypes}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        connectionLineStyle={connectionLineStyle}
        snapToGrid
        snapGrid={snapGrid}
        style={{ background: "#f0f2f5" }}
        attributionPosition="bottom-left"
        fitView
      >
        <Controls position="top-left" />
      </ReactFlow>
    </MDBox>
  );
};

export default ChatBotFlow;
