import {
  DialogContent,
  Grid,
  Collapse,
  Stack,
  IconButton,
  Divider,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { FieldArray, Formik } from "formik";
import * as Yup from "yup";
import MDButton from "components/MDButton";
import MDDialog from "components/MDDialog";
import { useMaterialUIController } from "context";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import MDSnackbar from "components/MDSnackbar";
import MDBox from "components/MDBox";
import { TransitionGroup } from "react-transition-group";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import RichTextEditor from "components/UIComponents/RichTextEditor/RichTextEditor";
import MDInput from "components/MDInput";
import { useDispatch, useSelector } from "react-redux";
import { createMessage } from "store/slice/message/messageSlice";
import moment from "moment";
import { createAndUpdateMessage } from "store/slice/message/messageSlice";
import { getChatbotFlow } from "store/slice/chatbotFlow/chatbotFlowSlice";

const MessageUpdateDialogBox = (props) => {
  const { dialogOpen, onClose, messageData } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const location = useLocation();
  const [controller] = useMaterialUIController();
  const { sidenavColor } = controller;

  const placesData = useSelector((state) => state.places?.selectedPlace);
  let placeId =
    placesData?._id || new URLSearchParams(location?.search).get("placeId");

  const handelUpdateMessage = (data) => {
    dispatch(createAndUpdateMessage(data))
      .unwrap()
      .then((res) => {
        const success = res?.success;
        if (success) {
          dispatch(getChatbotFlow(placeId));
          onClose();
        }
      })
      .catch((err) => {
        console.error("Error creating Message:", err);
      });
  };

  useEffect(() => {
    document.body.style.overflowY =
      dialogOpen || isMobile ? "hidden !important" : "";

    return () => {
      document.body.style.overflowY = "";
    };
  }, [dialogOpen]);

  return (
    <>
      <MDDialog
        dialogTitle={`Update Message`}
        open={dialogOpen}
        dialogClose={onClose}
        closeIcon={true}
        maxWidth="sm"
        fullScreen={isMobile}
      >
        <DialogContent>
          <Formik
            enableReinitialize={!isMobile}
            initialValues={{
              _id: messageData?._id,
              placeId: placeId,
              purpose: messageData?.purpose,
              title: messageData?.title || "",
              subTitle: messageData?.subTitle || "",
              buttonText: messageData?.buttonText || "",
              textMessage: messageData?.textMessage || "",
              simpleMessages: [
                {
                  message: messageData?.textResponse || "",
                },
              ],
              startTime: new Date(new Date().setHours(0, 0, 0)),
              endTime: new Date(new Date().setHours(23, 59, 59)),
              days: [
                "Sunday",
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday",
              ],
            }}
            // validationSchema={validationSchema}
            onSubmit={(value, actions) => {
              console.log("values", value);
              const payload = {
                ...value,
                startTime: moment(value?.startTime).format("HH:mm"),
                endTime: moment(value?.endTime).format("HH:mm"),
                simpleMessages: value.simpleMessages?.map((message) => ({
                  message: message?.message.replaceAll(
                    /class="wysiwyg-mention"/g,
                    `style="; pointer-events: none; text-decoration: none; color: unset; background-color: unset;"`
                  ),
                })),
              };
              console.log("Payload ===>", payload);
              handelUpdateMessage(payload);
            }}
          >
            {(props) => (
              <form onSubmit={props.handleSubmit}>
                <Grid container spacing={2} className="mt-1">
                  {props.values.purpose === "License Plate Form" ||
                  props.values.purpose === "Monthly Rate Form" ||
                  props.values.purpose === "Payment Card (Monthly)" ||
                  props.values.purpose === "Payment Card" ||
                  props.values.purpose === "Permit Creation Form" ? (
                    <>
                      <Grid item xs={12}>
                        <MDInput
                          name="title"
                          value={props.values.title}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          label="Title"
                          error={
                            props.errors.title && props.touched.title
                              ? true
                              : false
                          }
                          success={
                            props.errors.title && props.touched.title
                              ? false
                              : true
                          }
                          helperText={
                            props.errors.title && props.touched.title
                              ? props.errors.title
                              : null
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <MDInput
                          name="subTitle"
                          value={props.values.subTitle}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          label="Sub Title"
                          error={
                            props.errors.subTitle && props.touched.subTitle
                              ? true
                              : false
                          }
                          success={
                            props.errors.subTitle && props.touched.subTitle
                              ? false
                              : true
                          }
                          helperText={
                            props.errors.subTitle && props.touched.subTitle
                              ? props.errors.subTitle
                              : null
                          }
                        />
                      </Grid>
                    </>
                  ) : null}
                  {[
                    "Extension Link Expire",
                    "Validation Link Expire",
                    "Welcome Message (Validation)",
                    "Welcome Message",
                    "On Rate Selection",
                    "Payment Confirmation",
                    "Payment Confirmation (Monthly)",

                    "On Hour Selection",
                    "Unavailability",
                    "Unavailability (Extension)",
                    "Help",
                    "Learn More",
                    "Contact",
                    "Extension Welcome",
                    "Welcome Message (Parent Rate)",
                    "Second Step Validation Message",
                    "After LicensePlate Submission (Two Step Validation)",
                    "Payment Confirmation (Two Step Validation)",
                    "Welcome Message (Pay Now Validation Later Flow)",
                    "Payment Confirmation (Pay Now Validation Later Flow)",
                    "Permit Form Submit",
                    "Welcome Message (Permit)",
                  ].includes(props.values.purpose) ? (
                    <Grid item xs={12}>
                      <FieldArray name="simpleMessages">
                        {({ remove, push }) => (
                          <MDBox className="pt-2">
                            <TransitionGroup component="div" appear>
                              {props.values.simpleMessages?.map(
                                (message, index) => (
                                  <Collapse key={index}>
                                    <MDBox>
                                      {index !== 0 && (
                                        <MDBox mt={1}>
                                          <Stack
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="center"
                                            spacing={1}
                                          >
                                            <Divider
                                              orientation="horizontal"
                                              flexItem
                                              light
                                              sx={{
                                                width: `calc(100% - 40px)`,
                                                margin: "auto 0 !important",
                                              }}
                                            />
                                            {props.values.simpleMessages
                                              .length > 1 && (
                                              <IconButton
                                                color="error"
                                                size="small"
                                                onClick={() => remove(index)}
                                              >
                                                <ClearOutlinedIcon />
                                              </IconButton>
                                            )}
                                          </Stack>
                                        </MDBox>
                                      )}
                                      <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                          <MDBox>
                                            <RichTextEditor
                                              purpose={props.values.purpose}
                                              name={`simpleMessages.${index}.message`}
                                              value={message.message}
                                              setFieldValue={
                                                props.setFieldValue
                                              }
                                              error={
                                                props.errors.simpleMessages?.[
                                                  index
                                                ]?.message
                                              }
                                              helperText={
                                                props.errors.simpleMessages?.[
                                                  index
                                                ]?.message
                                              }
                                            />
                                          </MDBox>
                                        </Grid>
                                      </Grid>
                                    </MDBox>
                                  </Collapse>
                                )
                              )}
                            </TransitionGroup>
                            {/* <MDBox sx={{ mr: 2, mt: 3, mb: 1 }}>
                              <MDButton
                                variant="contained"
                                color={sidenavColor}
                                onClick={() => push({ message: "" })}
                              >
                                +
                              </MDButton>
                            </MDBox> */}
                          </MDBox>
                        )}
                      </FieldArray>
                    </Grid>
                  ) : null}

                  {(props.values.purpose === "Payment Card" ||
                    "License Plate Form" ||
                    props.values.purpose === "Payment Card (Monthly)") && (
                    <>
                      {/* <Grid item xs={12}>
                        <FieldArray name="simpleMessages">
                          {({ remove, push }) => (
                            <MDBox className="pt-2">
                              <TransitionGroup component="div" appear>
                                {props.values.simpleMessages.map(
                                  (message, index) => (
                                    <Collapse key={index}>
                                      <MDBox>
                                        {index !== 0 && (
                                          <MDBox mt={1}>
                                            <Stack
                                              direction="row"
                                              justifyContent="flex-start"
                                              alignItems="center"
                                              spacing={1}
                                            >
                                              <Divider
                                                orientation="horizontal"
                                                flexItem
                                                light
                                                sx={{
                                                  width: `calc(100% - 40px)`,
                                                  margin: "auto 0 !important",
                                                }}
                                              />
                                              {props.values.simpleMessages
                                                .length > 1 && (
                                                <IconButton
                                                  color="error"
                                                  size="small"
                                                  onClick={() => remove(index)}
                                                >
                                                  <ClearOutlinedIcon />
                                                </IconButton>
                                              )}
                                            </Stack>
                                          </MDBox>
                                        )}
                                        <Grid container spacing={2}>
                                          <Grid item xs={12}>
                                            <MDBox>
                                              <RichTextEditor
                                                purpose={props.values.purpose}
                                                name={`simpleMessages.${index}.message`}
                                                value={message.message}
                                                setFieldValue={
                                                  props.setFieldValue
                                                }
                                                error={
                                                  props.errors.simpleMessages?.[
                                                    index
                                                  ]?.message
                                                }
                                                helperText={
                                                  props.errors.simpleMessages?.[
                                                    index
                                                  ]?.message
                                                }
                                              />
                                            </MDBox>
                                          </Grid>
                                        </Grid>
                                      </MDBox>
                                    </Collapse>
                                  )
                                )}
                              </TransitionGroup>
                              {/* <MDBox sx={{ mr: 2, mt: 3, mb: 1 }}>
                                <MDButton
                                  variant="contained"
                                  color={sidenavColor}
                                  onClick={() => push({ message: "" })}
                                >
                                  +
                                </MDButton>
                              </MDBox> */}
                      {/* </MDBox>
                          )}
                        </FieldArray>
                      </Grid> */}
                      {(props.values.purpose === "Payment Card" ||
                        props.values.purpose === "Payment Card (Monthly)") && (
                        <Grid item xs={12}>
                          <MDInput
                            name="buttonText"
                            value={props.values.buttonText}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            label="Button Text"
                            error={
                              props.errors.buttonText &&
                              props.touched.buttonText
                                ? true
                                : false
                            }
                            success={
                              props.errors.buttonText &&
                              props.touched.buttonText
                                ? false
                                : true
                            }
                            helperText={
                              props.errors.buttonText &&
                              props.touched.buttonText
                                ? props.errors.buttonText
                                : null
                            }
                          />
                        </Grid>
                      )}
                    </>
                  )}
                  {[
                    "Expiration Reminder",
                    "Payment Confirmation SMS",
                    "Help SMS",
                    "Validation Reminder SMS",
                    "After LicensePlate Submission SMS (Two Step Validation)",
                    "Welcome Message (SMS)",
                  ].includes(props.values.purpose) && (
                    <Grid item xs={12}>
                      <MDInput
                        name="textMessage"
                        fullWidth
                        value={props.values.textMessage}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        label="Message"
                        error={
                          props.errors.textMessage && props.touched.textMessage
                            ? true
                            : false
                        }
                        success={
                          props.errors.textMessage && props.touched.textMessage
                            ? false
                            : true
                        }
                        helperText={
                          props.errors.textMessage && props.touched.textMessage
                            ? props.errors.textMessage
                            : null
                        }
                      />
                    </Grid>
                  )}

                  <Grid item xs={12} className="text-right">
                    <MDButton
                      variant="contained"
                      color={sidenavColor}
                      type="submit"
                    >
                      save
                    </MDButton>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </DialogContent>
      </MDDialog>
    </>
  );
};

export default MessageUpdateDialogBox;
