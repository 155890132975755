import MDBox from "components/MDBox";
import React, { useState } from "react";
import { Card, Avatar, Grid } from "@mui/material";
import googlePay from "../../../../assets/icons/google-pay.png.png";
import applePay from "../../../../assets/icons/apple-pay.png.png";
import CreditCard from "../../../../assets/icons/credit-card.png.png";
import MDTypography from "components/MDTypography";
import { Link } from "react-router-dom";

const ViewPaymentCard = ({ brandLogo }) => {
  const [isHovered, setIsHovered] = useState(false);

  const paymentOptions = [
    { icon: CreditCard, label: "Credit Card" },
    { icon: googlePay, label: "Google Pay" },
    { icon: applePay, label: "Apple Pay" },
  ];

  return (
    <>
      <MDBox>
        <Card>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12}>
              <MDBox mt={3} p={2}>
                <MDTypography
                  sx={{ textAlign: "center" }}
                  variant="h6"
                  fontSize="14px"
                >
                  Please Choose Your Payment Method to Confirm Your Parking
                  Session
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            {" "}
            {paymentOptions.map((option) => (
              <MDBox p={2} className="cursor-pointer">
                <Card>
                  <MDBox
                    p={1.5}
                    display="flex"
                    alignItems="center"
                    justifyContent="between"
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                  >
                    <MDBox
                      display="grid"
                      justifyContent="center"
                      alignItems="center"
                      width="3rem"
                      height="3rem"
                      borderRadius="lg"
                      variant="gradient"
                      mr={2}
                      p={1}
                    >
                      <Avatar
                        src={option.icon}
                        alt={option.label}
                        style={{ width: "100%", height: "100%" }}
                      />
                    </MDBox>
                    <MDBox>
                      <MDTypography
                        variant="subtitle2"
                        color={isHovered ? "info" : "text"}
                        fontWeight="bold"
                      >
                        {option.label}
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </Card>
              </MDBox>
            ))}{" "}
          </Grid>
          <Grid item xs={12}></Grid>
          <Grid item xs={12}>
            <MDBox
              mt={3}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <MDBox
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <MDTypography variant="caption" color="text">
                  Payments Powered By
                </MDTypography>
                <MDBox>
                  <img
                    src={brandLogo||""}
                    alt="card"
                    style={{
                      width: "80px",
                      height: "50px",
                      marginTop: "15px",
                      objectFit: "contain",
                      backgroundColor: "#FAF9F6",
                    }}
                  />
                </MDBox>
              </MDBox>
            </MDBox>
          </Grid>
          <Grid xs={12}>
            <MDBox p={2} textAlign="center">
              <MDTypography
                variant="caption"
                fontWeight="bold"
                fontSize="10px"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                By tapping pay, you agree to the&nbsp;
                <Link
                  href="https://www.dock.bot/terms-of-use/"
                  color="primary"
                  fontWeight="bold"
                  fontSize="10px"
                >
                  Terms of use
                </Link>
                &nbsp; and&nbsp;
                <Link
                  href="https://www.dock.bot/privacy-policy/"
                  color="primary"
                  fontWeight="bold"
                  fontSize="10px"
                >
                  Privacy Policy
                </Link>
              </MDTypography>
            </MDBox>
          </Grid>
        </Card>
      </MDBox>
    </>
  );
};

export default ViewPaymentCard;
