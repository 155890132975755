import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CreateAndUpdateMessageAPI } from "api/messageAPI";
import { DeleteMessageAPI } from "api/messageAPI";
import { GetMessageAPI, GetCalendarDataForMessagesAPI, GetDefaultMessageAPI } from "api/messageAPI";
import { CreateMessageAPI } from "api/messageAPI";

const initialState = {
  messages: [],
  calendarDataForMessages: [],
  loading: false,
  loadingCalendarDataForMessages: false,
  defaultMessages: []
};

export const createMessage = createAsyncThunk(
  "message/createMessage",
  async (data) => {
    console.log(data, "<==data");
    try {
      const response = await CreateMessageAPI(data);
      return response.data;
    } catch (err) {
      console.log("postMessage error ======>", err);
      return err;
    }
  }
);

export const createAndUpdateMessage = createAsyncThunk(
  "message/createAndUpdateMessage",
  async (data) => {
    console.log(data, "<==data");
    try {
      const response = await CreateAndUpdateMessageAPI(data);
      return response.data;
    } catch (err) {
      console.log("postMessage error ======>", err);
      return err;
    }
  }
);

export const getMessage = createAsyncThunk(
  "message/getMessages",
  async (data, { rejectWithValue }) => {
    try {
      const response = await GetMessageAPI(data);
      return response.data;
    } catch (error) {
      console.log("get message error ======>", error);
      return rejectWithValue(error.message);
    }
  }
);

export const getCalendarDataForMessages = createAsyncThunk(
  "message/getCalendarDataForMessages",
  async (data, { rejectWithValue }) => {
    try {
      const response = await GetCalendarDataForMessagesAPI(data);
      console.log(response.data);
      return response.data;
    } catch (error) {
      console.log("get message error ======>", error);
      return rejectWithValue(error.message);
    }
  }
);


export const deleteMessage = createAsyncThunk(
  "message/deleteMessage",
  async (data) => {
    try {
      const response = await DeleteMessageAPI(data);
      return response.data;
    } catch (err) {
      console.log("Delete error ======>", err);
      return err;
    }
  }
);

export const getDefaultMessage = createAsyncThunk(
  "message/getDefaultMessages",
  async (data, { rejectWithValue }) => {
    try {
      const response = await GetDefaultMessageAPI(data);
      return response.data;
    } catch (error) {
      console.log("get message error ======>", error);
      return rejectWithValue(error.message);
    }
  }
);

export const messageSlice = createSlice({
  name: "message",
  initialState,
  reducers: {
    setMessages: (state, action) => {
      state.messages = action.payload;
    },
    setPlaceIdForMessages: (state, action) => {
      state.placeId = action.payload;
    },
    setCalendarDataForMessages: (state, action) => {
      console.log("Setting up calendar data for messages", action);
      state.calendarDataForMessages = action.payload;
    }
  },
  extraReducers: (builder) =>
    builder
      .addCase(getCalendarDataForMessages.pending, (state) => {
        state.loadingCalendarDataForMessages = true;
      })
      .addCase(getCalendarDataForMessages.fulfilled, (state, { payload }) => {
        state.calendarDataForMessages = payload.calendarDataForMessages;
        state.loadingCalendarDataForMessages = false;
      })
      .addCase(getCalendarDataForMessages.rejected, (state) => {
        state.loadingCalendarDataForMessages = false;
      })
      .addCase(getMessage.pending, (state) => {
        state.loading = true;
      })
      .addCase(getMessage.fulfilled, (state, { payload }) => {
        state.messages = payload.messages;
        state.loading = false;
      })
      .addCase(getMessage.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getDefaultMessage.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDefaultMessage.fulfilled, (state, { payload }) => {
        state.defaultMessages = payload.messages;
        state.loading = false;
      })
      .addCase(getDefaultMessage.rejected, (state) => {
        state.loading = false;
      }),
});

export const { setMessages, setPlaceIdForMessages, setCalendarDataForMessages } = messageSlice.actions;
export default messageSlice.reducer;
