import AxiosDefault from "services/AxiosDefault";

export const GetChatbotFLowAPI = async (placeId) => {
  const response = await AxiosDefault({
    method: "GET",
    url: `/chatbotFlow/get/${placeId}`,
  });
  return response;
};

export const GetChatbotFLowTestAPI = async (placeId) => {
  const response = await AxiosDefault({
    method: "GET",
    url: `test/chatBotFlow/${placeId}`,
  });
  return response;
};